/* The source of truth for hardcoded URL paths */
export const artCollectorRoute = `research-centers/leonard-a-lauder-research-center/research-resources/modern-art-index-project`;
export const visitRoute = `/plan-your-visit`
export const aboutRoute = '/about-the-met'

/*
	Gentle reminder: if you update this function with a new type, you
	should also update the function in studio/lib/routes.js
*/
export const routeForDocument = ({
	_type = "",
	slug = { current: "" }
}) => {

	//Slug might be an object with a .current property or a string.
	const formattedSlug = slug?.current || slug;

	switch (_type) {
		case 'homepage':
			return `/`
		case 'welcomePage':
			return `/welcome`
		case 'planYourVisit':
			return `/plan-your-visit`
		case 'artCollector':
			return `/${artCollectorRoute}/${formattedSlug}`
		case 'article':
			return `/perspectives/${formattedSlug}`
		case 'temporaryArticle':
			return `/perspectives/${formattedSlug}`
		case 'audioGuideLandingPage':
			return `/audio-guide`
		case 'audioFile':
			return `/audio-guide/audio/${formattedSlug}`
		case 'audioPlaylist':
			return `/audio-guide/playlists/${formattedSlug}`
		case 'diningLandingPage':
			return `/plan-your-visit/dining`
		case 'collectionLandingPage':
			return `/art/collection`
		case 'crdCollection':
			return `/crdCollection/${formattedSlug}`
		case 'department':
			return `${aboutRoute}/departments/${formattedSlug}`
		case 'diningLocation':
			return '/plan-your-visit/dining'
		case 'exhibition':
			return `/exhibitions/${formattedSlug}`
		case 'groupVisitLandingPage':
			return `/plan-your-visit/group-visits`
		case 'groupVisitType':
			return `/plan-your-visit/group-visits/${formattedSlug}`
		case 'hubPage':
			return `/hubs/${formattedSlug}`
		case 'locationPage':
			return `${visitRoute}/${formattedSlug}`
		case "policy":
			return `/policies/${formattedSlug}`;
		case 'researchCenter':
			return `/research-centers/${formattedSlug}`
		case 'galleryViewer':
			return `/gallery-viewer/${formattedSlug}`
		case 'publication':
		case 'journal':
			return `/met-publications/${formattedSlug}`
		case 'periodRoomInteractive':
			return `/kiosk/period-room/${formattedSlug}`;
		case 'publicationLandingPage':
			return `/met-publications`
		case 'supportLandingPage':
			return '/support'
		case 'perspectivesLandingPage':
			return `/perspectives`
		case 'corporateSupport':
			return `/support/corporate-support`
		case 'friendsGroupsLandingPage':
			return `/support/friends-groups`
		case 'friendsGroup':
			return `/support/friends-groups/${formattedSlug}`
		case 'hostAnEventPage':
			return `/support/host-an-event`
		case 'annualReportPage':
			return `/support/annual-reports`
		case 'membershipFAQPage':
			return `/support/membership/membership-faqs`
		case 'volunteer':
			return `/support/volunteer/${formattedSlug}`
		case 'volunteerLandingPage':
			return `/support/volunteer`
		case 'wayOfGiving':
			return `/support/planned-giving/${formattedSlug}`
		case 'plannedGivingLandingPage':
			return `/support/planned-giving`
		case 'givingResource':
			return `/support/planned-giving/resources/${formattedSlug}`
		case 'series':
			return `/perspectives/series/${formattedSlug}`
	}
	console.warn(`The page type '${_type}' doesn't have a route associated with it.`);
	return null
}

export const routeForDocumentWithSubsection = ({ _type = "", slug = { current: "" }, subsection = "" }) => {
	// For now, do the naive implementation. Correctness will be checked on the CMS side
	return `${routeForDocument({ _type, slug })}${subsection ? subsection : ''}`;
}

/*
	Attempting to keep track of all research-center sub pages.
	With the updated "person group" template - there is now the possibility of custom routes
	that pull for the Role Slug field. pass "AllowCustom" to override the default behavior
*/
export const researchCenterRoutes = (slug = "", page, allowCustom = false) => {
	const baseRoute = routeForDocument({ _type: "researchCenter", slug: { current: slug } });
	switch (page) {
		case 'team':
			return `${baseRoute}/team`;
		case 'advisory-committee':
			return `${baseRoute}/advisory-committee`;
		case 'distinguished-scholars':
			return `${baseRoute}/distinguished-scholars`;
		case 'fellowships':
			return `${baseRoute}/fellowships`;
		case 'fellows':
			return `${baseRoute}/fellows`;
		case 'publication-grants':
			return `${baseRoute}/publication-grants`;
		case `research-resources`:
			return `${baseRoute}/research-resources`;
		case `curator-and-conservator-residencies`:
			return `${baseRoute}/curator-and-conservator-residencies`;
		default:
			return allowCustom ? `${baseRoute}/${page}` : baseRoute;
	}
}
